export const FONTS = {
  primary: 'apercu-regular-pro',
  headings: 'BrownLLWeb-Medium',
  labels: 'apercu-mono-bold',
};

export const COLORS = {
  black: '#000000',
  white: '#FFFFFF',
  grey: '#A4B2B8',
  red: '#EC4040',

  white100: '#FFFAEF',
  aux100: '#A79A89',
  blue100: '#BAD3E1',
  blue200: '#213C5A',
  blue300: '#14273D',
  blue400: '#0E1C2C',

  green100: '#C4E1BA',
  green200: '#315E4F',
  green300: '#163C36',
  green400: '#0E2F2A',
  green500: '#183F48',
  green600: '#0A232A',

  orange100: '#fff3e4',
  orange200: '#FFE5A4',
  orange300: '#F4A14A',
  orange400: '#FF774C',
  orange500: '#FFF5E7',
  orange600: '#FFE5C8',

  purple100: '#CCC3DD',
  purple200: '#37324E',
  purple300: '#27233D',
  purple400: '#1B1730',
  purple500: '#B6AFC7',
  purple600: '#BAB0D4',

  marsala400: '#52253B',

  teal100: '#BAE1D8',
  teal200: '#21485A',
  teal300: '#0E2E38',
  teal400: '#0A232B',
  teal500: '#DDC1A0',
  teal600: '#0E3038',
  teal700: '#576063',

  lightBG03: '#FFFAEF',
  beige100: '#E5DCCF',
};

export type SizeVariants = 'small' | 'mobile' | 'medium' | 'large';

export const SIZES = {
  small: 280,
  mobile: 480,
  medium: 768,
  large: 1200,
} as { [k: string]: number };

export const MEDIA_QUERIES = {
  small: SIZES.small + 'px',
  medium: SIZES.medium + 'px',
  large: SIZES.large + 'px',
};

export const BREAKPOINTS = [0, SIZES.medium, SIZES.large];

export type SpacingVariants =
  | 'none'
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | 'six'
  | 'seven'
  | 'eight'
  | 'twelve'
  | 'fifteen';

export const SPACING = {
  none: 0,
  half: 8,
  base: 16,
  one: 16,
  two: 32,
  three: 48,
  four: 64,
  five: 80,
  six: 96,
  seven: 112,
  eight: 128,
  nine: 144,
  ten: 160,
  eleven: 176,
  twelve: 192,
  fifteen: 240,
};

export const ANIMATION_CURVES = {
  bezier: [0.63, 0, 0.45, 1.0],
};

export const Z_INDEXES = {
  modal: 100,
  tooltip: 101,
};
