import { css, CSSObject, FlattenSimpleInterpolation } from 'styled-components';
import { SIZES } from '../styles';
import { BREAKPOINTS } from '../styles/theme-constants';

interface MediaQueryFactory {
  (css: CSSObject | TemplateStringsArray): string;
}

interface MediaQueries {
  customBreak: any;
  huge: MediaQueryFactory;
  large: MediaQueryFactory;
  medium: MediaQueryFactory;
  small: MediaQueryFactory;
  mobile: MediaQueryFactory;
}

// Iterate through the sizes and create a media template
// Example usage:
// const Content = styled.div`
//   height: 3em;
//   width: 3em;
//   background: papayawhip;

//   /* Now we have our methods on media and can use them instead of raw queries */
//   ${media.large`background: dodgerblue;`}
//   ${media.medium`background: mediumseagreen;`}
//   ${media.small`background: palevioletred;`}
// `;
const media: MediaQueries = Object.keys(SIZES).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${SIZES[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

media.customBreak = function (size) {
  return (...args) => css`
    @media (min-width: ${size}px) {
      ${css(...args)}
    }
  `;
};

const responsiveMedia = Object.values(BREAKPOINTS).map(media => {
  return (...args: TemplateStringsArray | FlattenSimpleInterpolation) => {
    return css`
      @media (min-width: ${media}px) {
        ${css(...args)};
      }
    `;
  };
});

export { media, responsiveMedia };
