import React, { ReactChildren, ReactNode } from 'react';

export type ProviderProps = {
  children: ReactChildren | ReactNode;
  scrollBehaviour?: 'inside' | 'outside';
};

export interface ContextProps {
  visible: boolean;
  handleModal: Function;
  modalContent: ReactNode;
  scrollBehaviour?: 'inside' | 'outside';
}

export const useModal = (): ContextProps => {
  const [visible, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState('');

  const handleModal = (content: string): void => {
    setModal(!visible);
    if (content) {
      setModalContent(content);
    }

    if (!visible) {
      if (typeof document !== 'undefined') document.body.classList.add('lockScroll');
    } else {
      if (typeof document !== 'undefined') document.body.classList.remove('lockScroll');
    }
  };

  return { visible, handleModal, modalContent };
};
