import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
interface ImageProps {
  src: string;
}

export const useImageQuery = ({ src }: ImageProps): FluidObject | null => {
  const defaultImageQuery = graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(defaultImageQuery);

  const image = data.images.edges.find(node => {
    return node.node.relativePath.includes(src);
  });

  if (!image) {
    throw new Error(`useImageQuery. Could not find: ${src}`);
  }
  return image.node.childImageSharp.fluid;
};
