import { media } from '@cheese-fondue/helpers';
import { COLORS, SPACING } from '@cheese-fondue/styles';
import { ANIMATION_CURVES, Z_INDEXES } from '@cheese-fondue/styles/theme-constants';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CloseIcon } from '../atoms/icons';
import { ModalContext, Provider } from './modal-context';
import { ContextProps, ProviderProps, useModal } from './modal-props';

type ElementCache = {
  el: Element;
  aria: string;
  tabIndex: string;
};

const ModalWrapper = styled(motion.div)<{ scroll: ContextProps['scrollBehaviour'] }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${({ scroll = 'inside' }) => (scroll === 'inside' ? 'center' : 'flex-start')};
  justify-content: center;
  z-index: ${Z_INDEXES.modal};
  background-color: ${COLORS.black}e0;
  overflow-y: ${({ scroll = 'inside' }) => (scroll === 'inside' ? 'initial' : 'auto')};
  padding: ${({ scroll = 'inside' }) => (scroll === 'inside' ? 'initial' : `${SPACING.four}px ${SPACING.two}px`)};

  ${media.medium`
     padding: ${({ scroll = 'inside' }) => (scroll === 'inside' ? 'initial' : `${SPACING.six}px ${SPACING.two}px`)};
  `}
`;

const ModalInner = styled.div<{ scroll: ContextProps['scrollBehaviour'] }>`
  position: relative;
  overflow-y: ${({ scroll = 'inside' }) => (scroll === 'inside' ? 'auto' : 'initial')};
  background-color: ${COLORS.white};
  width: 100%;
  ${media.medium`
    max-width: 800px;
  `}
  max-height: ${({ scroll = 'inside' }) => (scroll === 'inside' ? '100vh' : 'unset')};
  ${media.medium`
    max-height: ${({ scroll = 'inside' }) => (scroll === 'inside' ? '90vh' : 'unset')};
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: transparent;
  font-size: 2rem;
  font-weight: normal;
  margin: ${SPACING.one}px;
  padding: ${SPACING.one}px;
  z-index: 10;
  cursor: pointer;

  svg {
    display: block;
  }
`;

export const Modal = (): ReactElement | null => {
  const { modalContent, handleModal, visible, scrollBehaviour } = React.useContext(ModalContext);
  const [domReady, setDomReady] = React.useState(false);
  const closeButtonRef = useRef<HTMLButtonElement>();
  const mainContent = useRef<Element | null>();
  const modalRoot = useRef<Element | null>();
  const headerContent = useRef<Element | null>();
  const footerContent = useRef<Element | null>();

  const animateYoffset = 30;
  const focusableElements = useRef<ElementCache[]>([]);
  const activeElement = useRef<Element | null>();

  const focusClose = (): void => {
    closeButtonRef.current?.focus();
  };

  useEffect(() => {
    setDomReady(true);
    if (!mainContent.current && !headerContent.current && !footerContent.current) {
      const _modalRoot = document.querySelector('#modal-root');
      const _mainContent = document.querySelector('#main-content');
      const _headerContent = document.querySelector('#header-content');
      const _footerContent = document.querySelector('#footer-content');

      modalRoot.current = _modalRoot || null;
      mainContent.current = _mainContent || null;
      headerContent.current = _headerContent || null;
      footerContent.current = _footerContent || null;
    }

    if (mainContent.current && headerContent.current && footerContent.current) {
      if (!activeElement.current) {
        activeElement.current = document.activeElement;
      }
      //TODO: Refine selector to enabled elements
      if (focusableElements.current.length === 0) {
        focusableElements.current = [
          ...Array.prototype.slice.call(mainContent?.current?.querySelectorAll('*')),
          ...Array.prototype.slice.call(headerContent?.current?.querySelectorAll('*')),
          ...Array.prototype.slice.call(footerContent?.current?.querySelectorAll('*')),
        ].map(e => {
          return { tabIndex: e.getAttribute('tabIndex'), aria: e.getAttribute('aria-hidden'), el: e };
        });
      }
      if (visible) {
        focusClose();
        focusableElements.current?.forEach(e => {
          e.el.setAttribute('tabIndex', '-1');
          e.el.setAttribute('aria-hidden', 'true');
        });
      } else {
        focusableElements.current?.forEach(e => {
          if (e.tabIndex) {
            e.el.setAttribute('tabIndex', e.tabIndex);
          } else {
            e.el.removeAttribute('tabIndex');
          }
          if (e.aria) {
            e.el.setAttribute('aria-hidden', e.aria);
          } else {
            e.el.removeAttribute('aria-hidden');
          }
        });
        activeElement.current?.focus();
        activeElement.current = null;
      }
    }
  }, [mainContent, headerContent, footerContent, visible]);

  if (!modalRoot.current) return null;

  return domReady && visible
    ? ReactDOM.createPortal(
        <AnimatePresence>
          <ModalWrapper
            transition={ANIMATION_CURVES.bezier}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            role="modal"
            tabIndex={-1}
            scroll={scrollBehaviour}
          >
            <motion.div
              transition={ANIMATION_CURVES.bezier}
              initial={{ opacity: 0, y: `${animateYoffset}px` }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: `${-1 * animateYoffset}px` }}
            >
              <ModalInner scroll={scrollBehaviour}>
                <CloseButton
                  ref={closeButtonRef}
                  aria-label="Close"
                  onClick={() => handleModal()}
                  onKeyPress={() => handleModal()}
                >
                  <span aria-hidden="true">
                    <CloseIcon />
                  </span>
                </CloseButton>
                {modalContent}
              </ModalInner>
            </motion.div>
          </ModalWrapper>
        </AnimatePresence>,
        document.getElementById('modal-root')!,
      )
    : null;
};

export const ModalProvider = ({ children, scrollBehaviour = 'inside' }: ProviderProps): ReactElement => {
  const { visible, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ visible, handleModal, modalContent, scrollBehaviour }}>
      <Modal />
      {children}
    </Provider>
  );
};
