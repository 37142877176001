// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fees-and-charges-tsx": () => import("./../../../src/pages/fees-and-charges.tsx" /* webpackChunkName: "component---src-pages-fees-and-charges-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-templates-legal-template-jsx": () => import("./../../../src/templates/legal_template.jsx" /* webpackChunkName: "component---src-templates-legal-template-jsx" */)
}

