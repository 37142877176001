import { decomposeColor, recomposeColor, rgbToHex } from '@material-ui/core';

export const speckleTransparency = '9c';

/**
 * Very speckletackular background-image pattern that repeats a simple SVG with or without background color.
 * Speckle colors is the inverted value of the background color
 */
export const speckledBackground = (backgroundColor: string, transparentBackground = false): string => {
  const decomposedBackgroundColor = decomposeColor(backgroundColor);
  decomposedBackgroundColor.values[0] = 255 - decomposedBackgroundColor.values[0];
  decomposedBackgroundColor.values[1] = 255 - decomposedBackgroundColor.values[1];
  decomposedBackgroundColor.values[2] = 255 - decomposedBackgroundColor.values[2];
  const speckleColor = `${rgbToHex(recomposeColor(decomposedBackgroundColor))}${speckleTransparency}`;

  return `
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='300' height='207' viewBox='0 0 300 207' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='300' height='207' fill='${encodeURIComponent(
      transparentBackground ? 'none' : backgroundColor,
    )}'/%3e%3cg fill='${encodeURIComponent(
    speckleColor,
  )}'%3e%3crect x='34' y='8' width='1' height='1' /%3e%3crect x='64' y='2' width='1' height='1' /%3e%3crect opacity='0.1' x='63' y='2' width='1' height='1' /%3e%3crect x='62' y='36' width='1' height='1' /%3e%3crect x='77' y='96' width='1' height='1' /%3e%3crect x='162' y='11' width='1' height='1' fill-opacity='0.5'/%3e%3crect x='162' y='12' width='1' height='1' fill-opacity='0.2'/%3e%3crect x='147' y='21' width='1' height='1' /%3e%3crect x='198' y='49' width='1' height='1' /%3e%3crect x='249' y='70' width='1' height='1' /%3e%3crect x='215' y='140' width='1' height='1' /%3e%3crect x='230' y='170' width='1' height='1' /%3e%3crect x='260' y='136' width='1' height='1' /%3e%3crect x='261' y='136' width='1' height='1' /%3e%3crect x='296' y='38' width='1' height='1' fill-opacity='0.1'/%3e%3crect x='166' y='94' width='1' height='1' /%3e%3crect x='166' y='95' width='1' height='1' fill-opacity='0.8'/%3e%3crect x='168' y='97' width='1' height='1' fill-opacity='0.8'/%3e%3crect x='168' y='96' width='1' height='1' /%3e%3crect x='167' y='96' width='1' height='1' fill-opacity='0.2'/%3e%3crect x='198' y='50' width='1' height='1' fill-opacity='0.2'/%3e%3crect x='77' y='97' width='1' height='1' fill-opacity='0.5'/%3e%3crect x='30' y='106' width='1' height='1' /%3e%3crect x='68' y='166' width='1' height='1' /%3e%3crect x='47' y='170' width='1' height='1' /%3e%3crect x='141' y='166' width='1' height='1' fill-opacity='0.2'/%3e%3crect x='87' y='191' width='1' height='1' fill-opacity='0.2'/%3e%3c/g%3e%3c/svg%3e ");
    background-repeat: repeat;
  `;
};
