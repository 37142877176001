import { speckledBackground } from './backgrounds';
import { COLORS } from './theme-constants';

export const THEME = {
  color: COLORS.black,
  backgroundColor: COLORS.white,
  primary: COLORS.black,
  secondary: COLORS.grey,
  baseFontsize: 16, // pixel

  speckledBackground: (color: string, transparentBackground?: boolean) =>
    `${speckledBackground(color, transparentBackground)}`,
};
