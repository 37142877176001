import { createGlobalStyle, css } from 'styled-components';
import { THEME } from './theme';
import { COLORS, SIZES, SPACING } from './theme-constants';
import { typography } from './typography';
interface GlobalStyleProps {
  theme: string;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html {
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
  }

  * {
    box-sizing: border-box;
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    color: ${THEME.color};
    font-size: ${THEME.baseFontsize}px;
    ${typography.body}

    &.lockScroll {
      overflow-y: hidden;
    }
  }

  main.constrained {
    max-width: ${SIZES.large}px;
    margin: 0 auto;
  }

  h1, .h1 {
    ${typography.h1}

    &.xlarge {
      ${typography.h1XLarge}
    }
    
    &.xxlarge {
      ${typography.h1XXLarge}
    }
    
    &.xxxlarge {
      ${typography.h1XXXLarge}
    }

    &.xxxxlarge {
      ${typography.h1XXXXLarge}
    }
  }

  h2, .h2 {
    ${typography.h2}

    &.large {
      ${typography.h2Large}
    }
  }
  
  h3, .h3 {
    ${typography.h3}
  }
  
  h4, .h4 {
    ${typography.h4}
  }
  
  h5, .h5 {
    ${typography.h5}
  }

  
  p, label, li {
    ${typography.p}

    &.medium {
      ${typography.pMedium}
    }

    &.large {
      ${typography.pLarge}
    }
  }

  pre, .pre {
    ${typography.pre}

    &.small {
      ${typography.preSmall}
    }
  }

  button, .button {
    ${typography.button}
  }

  a {
    color: ${COLORS.blue400};
  }

  address {
    font-style: normal;
  }

  ul {
    padding-inline-start: ${SPACING.one}px;
    margin:0;
  }


  /* TODO: Refactor to something smarter */
  ${Object.keys(SPACING).map(key => {
    return css`
      .mt-${key} {
        margin-top: ${SPACING[key]}px;
      }
    `;
  })}
`;

export default GlobalStyle;
