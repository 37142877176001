import { THEME } from './theme';
import { FONTS, SIZES } from './theme-constants';

export const responsiveFontSize = (
  minFontSize: number,
  maxFontSize: number,
  letterSpacingPercentage = 0,
  minLineheight?: number | null,
  maxLineheight?: number | null,
): string => {
  const pixelsPerRem = THEME.baseFontsize;

  const minWidthPx = 360;
  const maxWidthPx = 1200;

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `
    font-size: clamp(${minFontSize}rem, ${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(
    4,
  )}vw, ${maxFontSize}rem);

    ${
      letterSpacingPercentage !== 0
        ? `letter-spacing: calc(${letterSpacingPercentage} * clamp(${minFontSize}rem, ${yAxisIntersection.toFixed(
            4,
          )}rem + ${(slope * 100).toFixed(4)}vw, ${maxFontSize}rem));`
        : ``
    }

    ${
      /* TODO: leverage media function */
      minLineheight && maxLineheight
        ? `
            line-height: ${minLineheight / 100};
            @media screen and (min-width: ${SIZES.medium}px) {
              line-height: ${maxLineheight / 100};
            }
          `
        : ``
    }
  `;
};

export type TypographyVariants =
  | 'body'
  | 'h1'
  | 'h1Variant'
  | 'h1XLarge'
  | 'h1XXLarge'
  | 'h1XXXLarge'
  | 'h1XXXXLarge'
  | 'h2'
  | 'h2Variant'
  | 'h3'
  | 'h3Variant'
  | 'h3Fit'
  | 'h4'
  | 'h5'
  | 'h6Variant'
  | 'p'
  | 'pVariant'
  | 'pLarge'
  | 'pSmall'
  | 'pSVariant'
  | 'pMedium'
  | 'button'
  | 'pre'
  | 'preSmall'
  | 'preXSmall'
  | 'preLarge'
  | 'preXLarge';

export const typography: { [key: string]: string } = {
  body: `
    font-family: ${FONTS.primary};
    `,

  h1: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 3rem;
    letter-spacing: -2.5px;
    `,

  h1Variant: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 52.8px;
    font-size: 48px;
    letter-spacing: -4%;
    `,

  h1XLarge: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 3.75rem;
    letter-spacing: -3px;
    `,

  h1XXLarge: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 5rem;
    ${responsiveFontSize(2.4, 5, -0.04, 105, 105)};
    `,

  h1XXXLarge: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    font-size: 6rem;
    line-height: 95%;
    ${responsiveFontSize(3, 6, -0.04, 105, 95)};
    `,

  h1XXXXLarge: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 95%;
    font-size: 9rem;
    ${responsiveFontSize(3.75, 9, -0.04, 105, 95)};
    `,

  h2: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 2rem;
    ${responsiveFontSize(1.8, 2, -0.04)};
    `,

  h2Variant: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 110%;
    font-size: 32px;
  `,

  h3: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 1.5rem;
    ${responsiveFontSize(1.3, 1.5, -0.04)};
    `,

  h3Variant: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 110%;
    font-size: 24px;
  `,

  h3Fit: `
  font-family: ${FONTS.headings};
  font-weight: normal;
  line-height: 110%;
  font-size: 1.5vw;
  `,

  h4: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 1.125rem;
    letter-spacing: -1px;
    `,

  h5: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 105%;
    font-size: 1rem;
    letter-spacing: -1px;
    `,

  h6: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 110%;
    font-size: 12px;
    `,

  h6Variant: `
    font-family: ${FONTS.headings};
    font-weight: normal;
    line-height: 110%;
    font-size: 15px;
  `,

  p: `
    font-family: ${FONTS.primary};
    font-size: ${THEME.baseFontsize}px;
    line-height: 125%;
    `,

  pVariant: `
    font-family: ${FONTS.headings};
    font-size: 20px;
    line-height: 125%;
    `,

  pSVariant: `
  font-family: ${FONTS.headings};
  font-size: 18px;
  line-height: 125%;
  `,

  pLarge: `
    font-family: ${FONTS.primary};
    line-height: 125%;
    font-size: 1.5rem;
    `,

  pMedium: `
    font-family: ${FONTS.primary};
    line-height: 125%;
    font-size: 1.25rem;
    `,

  pSmall: `
    font-family: ${FONTS.primary};
    line-height: 125%;
    font-size: 14px;
    `,

  button: `
    font-family: ${FONTS.labels};
    letter-spacing: 1px;
    `,

  pre: `
    font-family: ${FONTS.labels};
    font-size: 18px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 2px;
    `,

  preSmall: `
    font-family: ${FONTS.labels};
    font-size: 12px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 1px;
    `,

  preXSmall: `
    font-family: ${FONTS.labels};
    font-size: 10px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 1px;
    `,

  preLarge: `
    font-family: ${FONTS.labels};
    font-size: 20px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 1px;
    `,

  preXLarge: `
    font-family: ${FONTS.labels};
    font-size: 24px;
    text-transform: uppercase;
    line-height: 120%;
    letter-spacing: 1px;
    `,
};
