import React, { ReactElement } from 'react';
import { COLORS } from '../../../styles/theme-constants';

export enum LogoColor {
  Light,
  Dark,
}

interface Props {
  color: LogoColor;
}

export const Logo = ({ color = LogoColor.Light }: Props): ReactElement => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Delphia logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.679 31.986V32c1.196 0 2.348-.187 3.429-.532C26.954 29.654 32 23.416 32 16c0-8.837-7.163-16-16-16S0 7.163 0 16s7.163 16 16 16c.227 0 .454-.005.679-.014zM9.734 3.409a14.086 14.086 0 00-3.02 2.033v21.116c.91.801 1.924 1.486 3.02 2.033V3.409zm1.94-.791v26.764a14 14 0 003.26.639V1.979c-1.13.085-2.222.303-3.26.639zM1.938 16c0-3.18 1.056-6.113 2.836-8.468v16.936A13.999 13.999 0 011.939 16zm14.934 14.034c.24-.015.478-.035.714-.062a4.656 4.656 0 00-.714-9.216v9.278zm0-11.22a6.594 6.594 0 015.984 8.9 9.31 9.31 0 00-5.985-16.27v7.37zm0-9.31c6.123.104 11.054 5.1 11.054 11.248 0 1.234-.199 2.423-.566 3.534A13.997 13.997 0 0030.06 16c0-7.472-5.83-13.583-13.188-14.034v7.539z"
        fill={color === LogoColor.Light ? COLORS.orange100 : COLORS.blue400}
      />
    </svg>
  );
};
